import React, { useState } from "react"
import Layout from "../../../components/Layout"
import SEO from "../../../components/SEO/SEO"
import NavRoad from "../../../components/NavBar/NavBar"
import SeeAlso from "../../../components/SeeAlso/SeeAlso"
import AboutImg from "../../../components/Image/AboutImg"

import { lbsTokg, inTocm } from "../../../components/Script/converter"

import {
  Title,
  SubTitle,
  Select,
  Input,
  Span,
  Field,
  Control,
  StaticButton,
} from "../../../StyleComponents/styles"
import { FlexDiv, Table } from "../../../StyleComponents/pagesStyle"

const navRoadArray = [
  { name: "Calculator", link: "/calculator/" },
  {
    name: "Body Mass Fat Calculator",
    link: "/body-mass-fat-calculator/",
  },
]
const seeAlsoArray = ["/bmi-calculator", "/rep-max-calculator"]

function BodyMassFatCalculator(props) {
  let [myAge, setAge] = useState("")
  let [mySex, setmySex] = useState("Male")
  let [myFat, setFat] = useState("")
  let [weight, setweight] = useState("")
  let [height, setheight] = useState("")

  let [weightSelect, setweightSelect] = useState("Kg")
  let [heightSelect, setheightSelect] = useState("Cm")

  function changeWeight(e) {
    setweight(e.target.value)
    BodyMassFat(
      myAge,
      e.target.value,
      height,
      weightSelect,
      heightSelect,
      mySex
    )
  }
  function changeHeight(e) {
    setheight(e.target.value)
    BodyMassFat(
      myAge,
      weight,
      e.target.value,
      weightSelect,
      heightSelect,
      mySex
    )
  }
  function selectWeightUnit(e) {
    setweightSelect(e.target.value)
    BodyMassFat(myAge, weight, height, e.target.value, heightSelect, mySex)
  }
  function selectHeightUnit(e) {
    setheightSelect(e.target.value)
    BodyMassFat(myAge, weight, height, weightSelect, e.target.value, mySex)
  }
  function changingAge(e) {
    setAge(e.target.value)
    BodyMassFat(
      e.target.value,
      weight,
      height,
      weightSelect,
      heightSelect,
      mySex
    )
  }

  function changeSex(e) {
    setmySex(e.target.value)
    BodyMassFat(
      myAge,
      weight,
      height,
      weightSelect,
      heightSelect,
      e.target.value
    )
  }
  function BodyMassFat(age, weight, height, weightSelect, heightSelect, mySex) {
    let a, b, sum, bmi
    if (age && weight && height !== "") {
      if (weightSelect === "Lbs") {
        a = parseInt(lbsTokg(weight), 0)
      } else {
        a = parseInt(weight, 0)
      }
      if (heightSelect === "In") {
        b = parseInt(inTocm(height), 0)
      } else {
        b = parseInt(height, 0)
      }
      sum = (a / (b * b)) * 10000
      bmi = sum.toFixed(1)
      console.log(bmi)
      if (mySex === "Male") {
        setFat((1.2 * bmi + 0.23 * age - 16.2).toFixed(2))
      } else if (mySex === "Female") {
        setFat((1.2 * bmi + 0.23 * age - 5.4).toFixed(2))
      }
    } else {
      setFat("")
    }
  }

  // BMI = (pounds/inches^2) x 703.
  // Men Body Fat Percentage = (1.20 x BMI) / (0.23 x Age) – 16.2.
  // Women Body Fat Percentage = (1.20 x BMI) / (0.23 x Age) – 5.4.

  // Body Fat Formula For Women
  // Factor 1	(Total body weight x 0.732) + 8.987
  // Factor 2	Wrist measurement (at fullest point) / 3.140
  // Factor 3	Waist measurement (at naval) x 0.157
  // Factor 4	Hip measurement (at fullest point) x 0.249
  // Factor 5	Forearm measurement (at fullest point) x 0.434
  // Lean Body Mass	Factor 1 + Factor 2 - Factor 3 - Factor 4 + Factor 5
  // Body Fat Weight	Total bodyweight - Lean Body Mass
  // Body Fat Percentage	(Body Fat Weight x 100) / total bodyweight

  // Body Fat Formula For Men
  // Factor 1	(Total body weight x 1.082) + 94.42
  // Factor 2	Waist measurement x 4.15
  // Lean Body Mass	Factor 1 - Factor 2
  // Body Fat Weight	Total bodyweight - Lean Body Mass
  // Body Fat Percentage	(Body Fat Weight x 100) / total bodyweight
  return (
    <Layout location={props.location}>
      <SEO
        title="Body Mass Fat Calculator - Body Mass Fat Percentage Calculator"
        description="Body mass fat percentage calculator. Simple calculate your body mass percentage now how much fat mass should you have."
        keywords={[
          "Body Mass Fat Percentage Calculator, mass fat, body fat, body fat %, body fat Percentage, mass Percentage, body mass, bmi, bmr, Percentage body mass.",
        ]}
      />
      <NavRoad listPages={navRoadArray} />
      <div className="container">
        <Title>Body Mass Fat Calculator</Title>
        <SubTitle>Body Mass Fat Percentage Calculator</SubTitle>
        <FlexDiv maxWidth="500px">
          <Span>Select Genre </Span>
          <Select maxWidth="150px" className="mb-3">
            <select
              aria-label="unitSelector"
              value={mySex}
              onChange={changeSex}
              onBlur={changeSex}
            >
              <option value="Male"> Male </option>
              <option value="Female"> Female </option>
            </select>
          </Select>
          <Field>
            <Control>
              <StaticButton>AGE</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input
                radius="0"
                type="number"
                onChange={changingAge}
                value={myAge}
              />
            </Control>
            <Control>
              <StaticButton
                bottomLeftRadius="0"
                TopLeftRadius="0"
                bottomRightRadius="4px"
                topRightRadius="4px"
              ></StaticButton>
            </Control>
          </Field>

          <Field>
            <Control>
              <StaticButton>Weight</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input
                radius="0"
                id="number1"
                value={weight}
                onChange={changeWeight}
                type="number"
                placeholder="Your Weight"
              />
            </Control>
            <Control>
              <Select>
                <select
                  className="staticSelect"
                  aria-label="Weight-one"
                  value={weightSelect}
                  onChange={selectWeightUnit}
                  onBlur={selectWeightUnit}
                >
                  <option value="Kg">Kg</option>
                  <option value="Lbs">Lbs</option>
                </select>
              </Select>
            </Control>
          </Field>
          <Field>
            <Control>
              <StaticButton>Height</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input
                radius="0"
                id="number2"
                value={height}
                onChange={changeHeight}
                type="number"
                placeholder="Your Height"
              />
            </Control>
            <Control>
              <Select>
                <select
                  className="staticSelect"
                  aria-label="Height-one"
                  value={heightSelect}
                  onChange={selectHeightUnit}
                  onBlur={selectHeightUnit}
                >
                  <option value="Cm">Cm</option>
                  <option value="In">In</option>
                </select>
              </Select>
            </Control>
          </Field>

          {/* <Field>
            <Control>
              <StaticButton>BMI</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input
                radius="0"
                type="number"
                value={myBmiForFat}
                onChange={changingBmi}
              />
            </Control>
            <Control>
              <StaticButton
                bottomLeftRadius="0"
                TopLeftRadius="0"
                bottomRightRadius="4px"
                topRightRadius="4px"
              >
                {" "}
                kg/m<sup>2</sup>
              </StaticButton>
            </Control>
          </Field> */}

          <Field>
            <Control>
              <StaticButton>Your Fat % is</StaticButton>
            </Control>
            <Control className="expandedWidth">
              <Input radius="0" type="text" value={myFat} disabled />
            </Control>
            <Control>
              <StaticButton
                bottomLeftRadius="0"
                TopLeftRadius="0"
                bottomRightRadius="4px"
                topRightRadius="4px"
              >
                %{" "}
              </StaticButton>
            </Control>
          </Field>
        </FlexDiv>

        <br />
        <FlexDiv maxWidth="500px">
          <Table>
            <thead>
              <tr>
                <th>
                  <h5>How Much Fat Mass Should I Have?</h5>
                </th>
                <th>Women</th>
                <th>Men</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Athletes</td>
                <td>14-20%</td>
                <td>6-13%</td>
              </tr>
              <tr>
                <td>Fitness</td>
                <td>21-24%</td>
                <td>14-17%</td>
              </tr>
              <tr>
                <td>Acceptable</td>
                <td>25-31%</td>
                <td>18-25%</td>
              </tr>
              <tr>
                <td>Obese</td>
                <td>32% plus</td>
                <td>26% plus</td>
              </tr>
            </tbody>
          </Table>
        </FlexDiv>
        <br />
        <FlexDiv maxWidth="700px">
          <AboutImg filename="bodyfat.png" alt="Body Mass Fat Percentage" />
        </FlexDiv>
        <br />
        <SeeAlso seeAlsoList={seeAlsoArray} />
      </div>
    </Layout>
  )
}

export default BodyMassFatCalculator
